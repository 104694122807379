// 彭敏移动
<template>
  <div class="content">
    <div class="cover">
      <img src="../assets/imgs/pengmin_app/pm01.jpg" @click="toIndex" />
      <img src="../assets/imgs/pengmin_app/pm02.jpg" />
      <img src="../assets/imgs/pengmin_app/pm03.jpg" />
      <img src="../assets/imgs/pengmin_app/pm04.jpg" />
    </div>
    <!-- 视频 -->
    <div class="video">
      <!-- 注：poster为封面图，图片链接为网页链接http开头 -->
      <video
        id="video"
        controls="controls"
        src="http://pc.wzclxx.com/pengmin.mp4"
        poster="https://xx.wzclzx.com/7f012d62ae5120f282d938e7e0b258fc"
      ></video>
    </div>
    <!-- 图片 -->
    <div class="cover">
      <img src="../assets/imgs/pengmin_app/pm06.jpg" />
      <img src="../assets/imgs/pengmin_app/pm07.jpg" />
      <img src="../assets/imgs/pengmin_app/pm09.jpg" />
      <img src="../assets/imgs/pengmin_app/pm10.jpg" />
      <img src="../assets/imgs/pengmin_app/pm11.jpg" />
      <img src="../assets/imgs/pengmin_app/pm12.jpg" />
      <img src="../assets/imgs/pengmin_app/pm13.jpg" />
      <img src="../assets/imgs/pengmin_app/pm14.jpg" />
      <img src="../assets/imgs/pengmin_app/pm15.jpg" />
      <img src="../assets/imgs/pengmin_app/pm16.jpg" />
      <img src="../assets/imgs/pengmin_app/pm17.jpg" />
      <img src="../assets/imgs/pengmin_app/pm18.jpg" />
      <img src="../assets/imgs/pengmin_app/pm19.jpg" />
      <img src="../assets/imgs/pengmin_app/pm20.jpg" />
      <img src="../assets/imgs/pengmin_app/pm21.jpg" />
      <img src="../assets/imgs/pengmin_app/pm22.jpg" />
      <img src="../assets/imgs/pengmin_app/pm23.jpg" />
      <img src="../assets/imgs/pengmin_app/pm24.jpg" />
      <img src="../assets/imgs/pengmin_app/pm25.jpg" />
      <img src="../assets/imgs/pengmin_app/pm26.jpg" />
      <img src="../assets/imgs/pengmin_app/pm27.jpg" />
      <img src="../assets/imgs/pengmin_app/pm28.jpg" />
      <img src="../assets/imgs/pengmin_app/pm29.jpg" />
    </div>
  </div>
</template>
<script>
export default {
  methods:{
    toIndex() {
      location.href = "http://www.wzclxx.com/";
    },
  }
}
</script>

<style scoped lang="scss">
@import "../assets/css/common.css";
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

// 视频
.video {
  position: relative;
  width: 100%;
  height: 263px;
  background: #00277f;
  border-left: 5px solid #0032a3;
  border-right: 5px solid #0032a3;
  box-sizing: border-box;

  video {
    position: absolute;
    top: 0;
    width: 90%;
    height: 185px;
    margin-left: 5%;
    border-radius: 5px;
  }
}
</style>